import styled from 'styled-components';

export const ShadowBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const Modal = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
  outline: 0;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  flex-direction: column;
  width: auto;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  box-shadow: 5px 5px 10px #0000000d;
  padding: 37px 41px 20px 37px;
  @media (max-width: 767px) {
    padding: 37px 10px 20px 10px;
  }
`;

export const ButtonConfirm = styled.button`
  padding: 12px 40px 10px 40px;
  background: #2e3192 0% 0% no-repeat padding-box;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #5b5d6b;
  border-radius: 5px;
  outline: 0;
  @media (min-width: 1536px) {
    width: 174px;
    height: 52px;
    padding: 16px 60px 14px 60px;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ButtonSubmit = styled.button`
  padding: 12px 40px 10px 40px;
  background: #2e3192 0% 0% no-repeat padding-box;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #5b5d6b;
  border-radius: 5px;
  outline: 0;
  @media (min-width: 1536px) {
    width: 100%;
    height: 52px;
    padding: 16px 60px 14px 60px;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ButtonCancel = styled.button`
  padding: 12px 40px 10px 40px;
  color: #5b5d6b;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #5b5d6b;
  border-radius: 5px;
  outline: 0;
  @media (min-width: 1536px) {
    width: 174px;
    height: 52px;
    padding: 16px 60px 14px 60px;
    font-size: 18px;
    line-height: 22px;
  }
`;
