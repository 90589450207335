/* eslint-disable react/button-has-type */
import { Container, Modal, ShadowBackground, ButtonSubmit } from '../styled';
import Paragraph from 'components/Common/Text/Paragraph';
import Title from 'components/Common/Text/Title';
import React from 'react';

interface Props {
  setOTP: (newOTP: string) => void;
  OTP: string;
  onSubmit: () => void;
  onClose: () => void;
}

const ModalInputPasswordLogin: React.FunctionComponent<Props> = (props: Props) => {
  const { setOTP, OTP, onSubmit, onClose } = props;

  return (
    <ShadowBackground>
      <Modal>
        <Container style={{ width: '700px', maxWidth: '100%' }}>
          <div className="flex flex-row justify-end items-center mb-2">
            <button onClick={onClose} className="text-3xl pb-5">
              x
            </button>
          </div>
          <div className="pb-[100px] w-full">
            <div className="flex flex-col mb-6 xl:mt-1 gap-2 xl:gap-4 justify-between items-center">
              <Title level={4}>Security Authentication</Title>
            </div>
            <Paragraph level={4} textAlign="left">
              Please enter your GA code
            </Paragraph>
            <div className="mt-6 w-full">
              <input
                autoComplete="false"
                type="text"
                className="w-full py-4  border-[2px] px-4"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
                placeholder="GA code"
              />
            </div>
            <ButtonSubmit onClick={onSubmit} className="w-full mt-6" type="button">
              Confirm
            </ButtonSubmit>
          </div>
        </Container>
      </Modal>
    </ShadowBackground>
  );
};

export default ModalInputPasswordLogin;
